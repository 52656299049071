import axios from "axios";

export const followUser = (uid: string, accessToken: string) => {
  return axios.post(
    `https://api.onelight.app/api/userfollow?uid=${uid}`,
    {},
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
};
