import { sendEmailVerification } from "firebase/auth";
import { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "../../modal/Modal";
import { StepProps } from "./StepFirst";

export const StepFourth: FunctionComponent<StepProps> = ({
  user,
  setShowHeader,
}) => {
  useEffect(() => {
    setShowHeader?.(false);
  }, []);
  const [open, setOpen] = useState(false);
  return (
    <div>
      {open && (
        <Modal
          isError={false}
          setOpen={setOpen}
          errorMessage="Email Verification has been sent"
        />
      )}
      <h1 className="header center">Verify your email adress</h1>
      <p className="base-text">
        Last step! Please verify your email address, we sent you a confirmation
        email.
      </p>
      <button
        className="button-primary base-text-white"
        onClick={() =>
          (window.location.href = "https://getonelight.com/thankyou/")
        }
      >
        CONTINUE
      </button>
      <div className="have-container">
        <p className="base-text">Didn’t receive our email?</p>
        <button
          onClick={() => {
            if (user) {
              sendEmailVerification(user)
                .then(() => setOpen(true))
                .catch(() => {
                  console.log("err");
                });
            }
          }}
          className="text-button base-text-white"
        >
          Send again
        </button>
      </div>
    </div>
  );
};
