import Logo from "../assets/logo.png";

export const ThankYou = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 20,
      }}
    >
      <img className="center logo " src={Logo} alt="" />
      <h1 className="header forgot-password">Thank you for using Onelight!</h1>
      <button
        onClick={() =>
          (window.location.href = "https://getonelight.com/thankyou/")
        }
        className="button-primary base-text-white center"
      >
        CONTINUE
      </button>
    </div>
  );
};
