import { useState } from "react";
import "./App.css";
import { ForgotPassword } from "./screens/ForgotPassword";
import { Login } from "./screens/Login";
import { Register } from "./screens/Register";
import { ThankYou } from "./screens/ThankYou";

function App() {
  const [page, setPage] = useState(0);
  return (
    <div className="App">
      <video
        style={{ width: "100vw", height: "100vh", objectFit: "cover" }}
        autoPlay
        muted
        loop
      >
        <source
          src="https://getonelight.com/OneLightSplashPage_NoFilter.mp4"
          type="video/mp4"
        />
      </video>
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          backgroundColor: "rgba(0,0,0,0.2)",
          justifyContent: "flex-end",
        }}
      >
        <div className="mainContainer">
          {page === 2 && <Login setPage={setPage} />}
          {page === 1 && <ThankYou />}
          {page === 0 && <Register setPage={setPage} />}
          {page === 3 && <ForgotPassword setPage={setPage} />}
        </div>
      </div>
    </div>
  );
}

export default App;
