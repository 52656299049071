import {
  AuthErrorCodes,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { FunctionComponent, useEffect, useState } from "react";
import GoogleButton from "react-google-button";
import Logo from "../assets/logo.png";
import { Input } from "../components/input/Input";
import { Modal } from "../components/modal/Modal";
import { updateAudiences } from "../components/stepper/steps/api/postUpdateAudience";
import { appleProvider, auth } from "../firebase";
import { followUser } from "./api/followUser";
import { getUser } from "./api/getUser";
import { getUserRefered } from "./api/getUserRefered";
import AppleIcon from "./apple.svg";

export interface ScreenProps {
  setPage: (val: number) => void;
}

export const Login: FunctionComponent<ScreenProps> = ({ setPage }) => {
  const [email, setEmail] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [errModalOpen, setErrModalOpen] = useState(false);
  const [name, setname] = useState("");
  const handleAppleSignIn = () => {
    signInWithPopup(auth, appleProvider)
      .then(async (res) => {
        if (res.user) {
          const token = await res.user.getIdToken();
          getUser(token).then(() => {
            var sp = new URLSearchParams(window.location.search);
            updateAudiences(
              {
                allowPush: true,
                friends: false,
                friendsPlus: false,
                innerCircle: false,
                otherUserId: sp.get("u") ?? "",
              },
              token
            ).catch((err) => {
              console.log(err);
            });
            followUser(sp.get("u") ?? "", token)
              .then(
                () =>
                  (window.location.href = "https://getonelight.com/thankyou/")
              )
              .catch(() => {
                window.location.href = "https://getonelight.com/thankyou/";
              });
          });
        }
      })
      .catch((error) => {
        seterrorMessage("Sign in with google failed, please try again later");
        setErrModalOpen(true);
      });
  };
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (res) => {
        if (res.user) {
          const token = await res.user.getIdToken();
          getUser(token).then(() => {
            var sp = new URLSearchParams(window.location.search);
            updateAudiences(
              {
                allowPush: true,
                friends: false,
                friendsPlus: false,
                innerCircle: false,
                otherUserId: sp.get("u") ?? "",
              },
              token
            ).catch((err) => {
              console.log(err);
            });
            followUser(sp.get("u") ?? "", token)
              .then(
                () =>
                  (window.location.href = "https://getonelight.com/thankyou/")
              )
              .catch(() => {
                window.location.href = "https://getonelight.com/thankyou/";
              });
          });
        }
      })
      .catch((error) => {
        seterrorMessage("Sign in with google failed, please try again later");
        setErrModalOpen(true);
      });
  };
  useEffect(() => {
    getUserRefered(new URLSearchParams(window.location.search).get("u") ?? "")
      .then((res) => {
        setname(res.data.displayname);
      })
      .catch((err) => {});
  }, []);
  return (
    <div className="container">
      {errModalOpen && (
        <Modal setOpen={setErrModalOpen} errorMessage={errorMessage} />
      )}
      <img className=" logo" src={Logo} alt="logo onelight" />
      {name && (
        <h1 style={{ textAlign: "start" }} className="base-text">
          You were referred by{" "}
          <span style={{ color: "white", fontWeight: 700 }}>{name}</span>
        </h1>
      )}
      <div className="login-input-container">
        <Input onChange={(e) => setEmail(e)} type="email" placeholder="Email" />
        <Input
          onChange={(e) => setPassword(e)}
          type="password"
          placeholder="Password"
        />
      </div>
      <button
        onClick={() => setPage(3)}
        className="base-text-white text-button align-left forgot-password"
      >
        Forgot password?
      </button>
      <button
        className="button-primary base-text-white"
        onClick={() => {
          signInWithEmailAndPassword(auth, email, password)
            .then(async (res) => {
              if (res.user) {
                const token = await res.user.getIdToken();
                getUser(token).then(() => {
                  var sp = new URLSearchParams(window.location.search);

                  followUser(sp.get("u") ?? "", token)
                    .then(async () => {
                      await updateAudiences(
                        {
                          allowPush: true,
                          friends: false,
                          friendsPlus: false,
                          innerCircle: false,
                          otherUserId: sp.get("u") ?? "",
                        },
                        token
                      ).catch((err) => {
                        console.log(err);
                      });
                      window.location.href =
                        "https://getonelight.com/thankyou/";
                    })
                    .catch(() => {
                      window.location.href =
                        "https://getonelight.com/thankyou/";
                    });
                });
              }
            })
            .catch((err) => {
              if (
                err.code === AuthErrorCodes.INVALID_EMAIL ||
                err.code === AuthErrorCodes.INVALID_PASSWORD ||
                err.code === AuthErrorCodes.USER_DELETED
              ) {
                seterrorMessage("Login failed, wrong email or password");
                setErrModalOpen(true);
              } else {
                seterrorMessage(
                  "Login failde, something went wrong. Please try again later"
                );
                setErrModalOpen(true);
              }
            });
        }}
      >
        LOG IN
      </button>
      <div className="devide"></div>
      <div className="have-container">
        <p className="base-text">Don’t have an account?</p>

        <button
          onClick={() => setPage(0)}
          className="text-button base-text-white"
        >
          Sign up
        </button>
      </div>
      <GoogleButton
        onClick={() => googleSignIn()}
        style={{ backgroundColor: "white", color: "black", width: "100%" }}
      />
      <button
        style={{ marginTop: 12 }}
        className="appleButton"
        onClick={handleAppleSignIn}
      >
        <img src={AppleIcon} alt="apple icon" className="appleIcon" />
        <p className="appleButtonText">Continue with Apple</p>
      </button>
    </div>
  );
};
