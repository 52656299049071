import { User } from "firebase/auth";
import { FunctionComponent, useState } from "react";
import { StepFirst } from "./steps/StepFirst";
import { StepFourth } from "./steps/StepFourth";
import { StepSecond } from "./steps/StepSecond";
export interface StepperProps {
  setPage: (val: number) => void;
  setShowHeader: (val: boolean) => void;
}
export const Stepper: FunctionComponent<StepperProps> = ({
  setPage,
  setShowHeader,
}) => {
  const [step, setStep] = useState(0);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userPass, setUserPass] = useState({ email: "", pass: "" });
  return (
    <div>
      {step === 0 && (
        <StepFirst
          setStep={setStep}
          setShowHeader={setShowHeader}
          setPage={setPage}
          setUserPass={setUserPass}
        />
      )}
      {step === 1 && (
        <StepSecond setStep={setStep} userPass={userPass} setUser={setUser} />
      )}
      {step === 2 && (
        <StepFourth setStep={setStep} user={user} setPage={setStep} />
      )}
    </div>
  );
};
