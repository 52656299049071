import { FunctionComponent, useState } from "react";
import Logo from "../assets/logo.png";
import { Input } from "../components/input/Input";

import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
import { ScreenProps } from "./Login";

export const ForgotPassword: FunctionComponent<ScreenProps> = ({ setPage }) => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  return emailSent ? (
    <div className="container">
      <img className="center logo" src={Logo} alt="logo onelight" />
      <h1 className="header center">CHANGE PASSWORD EMAIL HAS BEEN SENT</h1>

      <button
        className="button-primary base-text-white"
        onClick={() => setPage(2)}
      >
        RETURN TO LOG IN
      </button>
    </div>
  ) : (
    <div className="container">
      <img className="center logo" src={Logo} alt="logo onelight" />
      <h1 className="header center">Forgot password</h1>
      <div className="login-input-container">
        <Input onChange={(e) => setEmail(e)} type="email" placeholder="Email" />
      </div>

      <button
        className="button-primary base-text-white"
        onClick={() => {
          sendPasswordResetEmail(auth, email)
            .then(() => {
              setEmailSent(true);
            })
            .catch(() => alert("wrong email"));
        }}
      >
        SEND
      </button>
      <div className="have-container">
        <p className="base-text">Return to</p>

        <button
          onClick={() => setPage(2)}
          className="text-button base-text-white"
        >
          Log In
        </button>
      </div>
    </div>
  );
};
