import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { debounce } from "lodash";
import { FunctionComponent, useCallback, useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { auth } from "../../../firebase";
import { followUser } from "../../../screens/api/followUser";
import { getPhoneExists } from "../../../screens/api/getPhoneExists";
import { getUser } from "../../../screens/api/getUser";
import { Input } from "../../input/Input";
import { StepProps } from "./StepFirst";
import { createUser } from "./api/postCreateUser";
import { updateAudiences } from "./api/postUpdateAudience";

export const StepSecond: FunctionComponent<StepProps> = ({
  setStep,
  userPass,
  setUser,
}) => {
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [phone, setPhone] = useState("");
  const [userNote, setUserNote] = useState("");
  const [gender, setGender] = useState<number | null>(null);
  const [inner, setInner] = useState(true);
  const [friendsPlus, setfriendsPlus] = useState(true);
  const [everyone, setEveryone] = useState(false);
  const [friends, setFriends] = useState(true);
  const [isPhoneUsed, setIsPhoneUsed] = useState<boolean | null>(null);
  const debounceSearch = useCallback(
    debounce((data: string) => {
      if (data)
        getPhoneExists(data.replace("+", ""))
          .then((res) => {
            if (!data) setIsPhoneUsed(null);
            else setIsPhoneUsed(res.data.isAvailable);
          })
          .catch((err) => {
            console.error(err);
          });
    }, 500),
    []
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <Input onChange={setName} placeholder="Your name*" type="text" />
      <Input
        onChange={setDisplayName}
        placeholder="Display name*"
        type="text"
      />
      <PhoneInput
        className="input-text input"
        defaultCountry="ca"
        value={phone}
        countrySelectorStyleProps={{
          buttonStyle: { background: "transparent", border: "none" },
          dropdownArrowStyle: { borderTopColor: "white" },
          dropdownStyleProps: {
            listItemStyle: {
              color: "#fff",
            },
            style: {
              border:
                " 1px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
              borderRadius: 8,
              background: "#1f272a",
            },
          },
        }}
        inputStyle={{
          width: "80%",
          border: "none",
          background: "transparent",
          color: "#21bbb3",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
        }}
        onChange={(phone) => {
          setPhone(phone);
          debounceSearch(phone);
        }}
      />
      {!isPhoneUsed && (
        <p className="error-text">Phone number already in use!!!</p>
      )}

      <Input
        onChange={setUserNote}
        placeholder="Usernote / Company"
        type="text"
      />

      <select
        className="select"
        onChange={(e) => setGender(Number(e.target.value))}
        name=""
        id=""
      >
        <option>Select your gender</option>
        <option value={1}>Male</option>
        <option value={2}>Female</option>
        <option value={3}>Other</option>
      </select>
      <div>
        <p style={{ textAlign: "left" }} className="helper-text">
          WHO CAN DIRECT MESSAGE ME
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={() => setInner(!inner)}
            className="text-button icon-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M17.46 6.16992C19.46 7.55992 20.84 9.76992 21.12 12.3199"
                stroke={friendsPlus ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.98999 12.3702C4.24999 9.83021 5.60999 7.62021 7.58999 6.22021"
                stroke={inner ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.68994 20.9399C9.84994 21.5299 11.1699 21.8599 12.5599 21.8599C13.8999 21.8599 15.1599 21.5599 16.2899 21.0099"
                stroke={inner ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.56 7.70014C14.0954 7.70014 15.34 6.45549 15.34 4.92014C15.34 3.38479 14.0954 2.14014 12.56 2.14014C11.0247 2.14014 9.78003 3.38479 9.78003 4.92014C9.78003 6.45549 11.0247 7.70014 12.56 7.70014Z"
                stroke={inner ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.33005 19.9199C6.8654 19.9199 8.11005 18.6752 8.11005 17.1399C8.11005 15.6045 6.8654 14.3599 5.33005 14.3599C3.7947 14.3599 2.55005 15.6045 2.55005 17.1399C2.55005 18.6752 3.7947 19.9199 5.33005 19.9199Z"
                stroke={inner ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.67 19.9199C21.2054 19.9199 22.45 18.6752 22.45 17.1399C22.45 15.6045 21.2054 14.3599 19.67 14.3599C18.1347 14.3599 16.89 15.6045 16.89 17.1399C16.89 18.6752 18.1347 19.9199 19.67 19.9199Z"
                stroke={inner ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p style={{ color: inner ? "#24BEB5" : "#808080" }}>Inner circle</p>
          </button>
          <button
            onClick={() => setfriendsPlus(!friendsPlus)}
            className="text-button icon-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M19.6666 9C19.6666 10.45 19.2366 11.78 18.4966 12.89C17.4166 14.49 15.7066 15.62 13.7166 15.91C13.3766 15.97 13.0266 16 12.6666 16C12.3066 16 11.9566 15.97 11.6166 15.91C9.62663 15.62 7.91663 14.49 6.83663 12.89C6.09663 11.78 5.66663 10.45 5.66663 9C5.66663 5.13 8.79663 2 12.6666 2C16.5366 2 19.6666 5.13 19.6666 9Z"
                stroke={friendsPlus ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.9166 18.4699L20.2666 18.8599C19.8966 18.9499 19.6066 19.2299 19.5266 19.5999L19.1766 21.0699C18.9866 21.8699 17.9666 22.1099 17.4366 21.4799L12.6666 15.9999L7.89658 21.4899C7.36658 22.1199 6.34658 21.8799 6.15658 21.0799L5.80658 19.6099C5.71658 19.2399 5.42658 18.9499 5.06658 18.8699L3.41658 18.4799C2.65658 18.2999 2.38658 17.3499 2.93658 16.7999L6.83658 12.8999C7.91658 14.4999 9.62658 15.6299 11.6166 15.9199C11.9566 15.9799 12.3066 16.0099 12.6666 16.0099C13.0266 16.0099 13.3766 15.9799 13.7166 15.9199C15.7066 15.6299 17.4166 14.4999 18.4966 12.8999L22.3966 16.7999C22.9466 17.3399 22.6766 18.2899 21.9166 18.4699Z"
                stroke={friendsPlus ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.2467 5.98L13.8367 7.15999C13.9167 7.31999 14.1267 7.48 14.3167 7.51L15.3867 7.68999C16.0667 7.79999 16.2267 8.3 15.7367 8.79L14.9067 9.61998C14.7667 9.75998 14.6867 10.03 14.7367 10.23L14.9767 11.26C15.1667 12.07 14.7367 12.39 14.0167 11.96L13.0167 11.37C12.8367 11.26 12.5367 11.26 12.3567 11.37L11.3567 11.96C10.6367 12.38 10.2067 12.07 10.3967 11.26L10.6367 10.23C10.6767 10.04 10.6067 9.75998 10.4667 9.61998L9.63672 8.79C9.14672 8.3 9.30672 7.80999 9.98672 7.68999L11.0567 7.51C11.2367 7.48 11.4467 7.31999 11.5267 7.15999L12.1167 5.98C12.4067 5.34 12.9267 5.34 13.2467 5.98Z"
                stroke={friendsPlus ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p style={{ color: friendsPlus ? "#24BEB5" : "#808080" }}>
              Friends+
            </p>
          </button>
          <button
            onClick={() => setFriends(!friends)}
            className="text-button icon-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M12.3334 12C15.0948 12 17.3334 9.76142 17.3334 7C17.3334 4.23858 15.0948 2 12.3334 2C9.57195 2 7.33337 4.23858 7.33337 7C7.33337 9.76142 9.57195 12 12.3334 12Z"
                stroke={friends ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.74341 22C3.74341 18.13 7.5934 15 12.3334 15C13.2934 15 14.2234 15.13 15.0934 15.37"
                stroke={friends ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.3334 18C22.3334 18.75 22.1234 19.46 21.7534 20.06C21.5434 20.42 21.2734 20.74 20.9634 21C20.2634 21.63 19.3434 22 18.3334 22C16.8734 22 15.6034 21.22 14.9134 20.06C14.5434 19.46 14.3334 18.75 14.3334 18C14.3334 16.74 14.9134 15.61 15.8334 14.88C16.5234 14.33 17.3934 14 18.3334 14C20.5434 14 22.3334 15.79 22.3334 18Z"
                stroke={friends ? "#24BEB5" : "#808080"}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.7734 18L17.7634 18.99L19.8934 17.02"
                stroke={friends ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p style={{ color: friends ? "#24BEB5" : "#808080" }}>Friends</p>
          </button>
          <button
            onClick={() => setEveryone(!everyone)}
            className="text-button icon-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M17.3876 10.8967C18.7826 10.7007 19.8566 9.50473 19.8596 8.05573C19.8596 6.62773 18.8186 5.44373 17.4536 5.21973"
                stroke={everyone ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.2285 14.2505C20.5795 14.4525 21.5225 14.9255 21.5225 15.9005C21.5225 16.5715 21.0785 17.0075 20.3605 17.2815"
                stroke={everyone ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.3867 14.6641C9.17267 14.6641 6.42767 15.1511 6.42767 17.0961C6.42767 19.0401 9.15567 19.5411 12.3867 19.5411C15.6007 19.5411 18.3447 19.0591 18.3447 17.1131C18.3447 15.1671 15.6177 14.6641 12.3867 14.6641Z"
                stroke={everyone ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.3867 11.888C14.4957 11.888 16.2057 10.179 16.2057 8.069C16.2057 5.96 14.4957 4.25 12.3867 4.25C10.2777 4.25 8.56766 5.96 8.56766 8.069C8.55966 10.171 10.2567 11.881 12.3587 11.888H12.3867Z"
                stroke={everyone ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.38466 10.8967C5.98866 10.7007 4.91566 9.50473 4.91266 8.05573C4.91266 6.62773 5.95366 5.44373 7.31866 5.21973"
                stroke={everyone ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.54382 14.2505C4.19282 14.4525 3.24982 14.9255 3.24982 15.9005C3.24982 16.5715 3.69382 17.0075 4.41182 17.2815"
                stroke={everyone ? "#24BEB5" : "#808080"}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p style={{ color: everyone ? "#24BEB5" : "#808080" }}>Everyone</p>
          </button>
        </div>
      </div>
      <div style={{ display: "flex", gap: 16 }}>
        <button
          className="button-secondary base-text-white"
          onClick={() => {
            setStep(0);
          }}
        >
          GO BACK
        </button>
        <button
          className="button-primary base-text-white"
          disabled={
            !name ||
            !displayName ||
            !phone ||
            !userNote ||
            gender === null ||
            isPhoneUsed !== true
          }
          onClick={() => {
            createUserWithEmailAndPassword(
              auth,
              userPass?.email ?? "",
              userPass?.pass ?? ""
            ).then((res) => {
              if (res.user) {
                createUser({
                  newUser: {
                    displayname: displayName,
                    everyone: everyone,
                    friends: friends,
                    phone: phone,
                    firebaseid: res.user.uid,
                    genderId: gender ?? 3,
                    friendsPlus: friendsPlus,
                    innerCircle: inner,
                    isTastemaker: false,
                    name: name,
                    lastActive: new Date(),
                    usernote: userNote,
                    lastLat: 0,
                    lastLong: 0,
                    imageUri:
                      "https://firebasestorage.googleapis.com/v0/b/onelight-380820.appspot.com/o/images%2Fblankprofile.png?alt=media&token=e52d1294-549a-4182-a79e-be3484af5d01",
                  },
                })
                  .then(async () => {
                    sendEmailVerification(res.user);
                    if (res.user) {
                      const token = await res.user.getIdToken();
                      getUser(token).then(() => {
                        var sp = new URLSearchParams(window.location.search);

                        followUser(sp.get("u") ?? "", token)
                          .then(async () => {
                            await updateAudiences(
                              {
                                allowPush: true,
                                friends: false,
                                friendsPlus: false,
                                innerCircle: false,
                                otherUserId: sp.get("u") ?? "",
                              },
                              token
                            ).catch((err) => {
                              console.log(err);
                            });
                            setStep(2);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      });
                    }
                    setUser?.(res.user);
                    setStep(2);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            });
          }}
        >
          NEXT
        </button>
      </div>
    </div>
  );
};
