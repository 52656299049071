import axios from "axios";
export interface UserAudienceObject {
  otherUserId: string;
  friends: boolean;
  friendsPlus: boolean;
  innerCircle: boolean;
  allowPush: boolean;
}
export const updateAudiences = async (
  data: UserAudienceObject,
  token: string
) => {
  return await axios.post(`https://api.onelight.app/api/useraudiences`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
