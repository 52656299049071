import { FunctionComponent, HTMLInputTypeAttribute } from "react";

interface InputProps {
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  onChange?: (text: string) => void;
  pattern?: string;
}

export const Input: FunctionComponent<InputProps> = ({
  type = "text",
  placeholder,
  pattern,
  onChange,
}) => {
  return (
    <input
      onChange={(e) => onChange?.(e.target.value)}
      className="input-text input"
      type={type}
      pattern={pattern}
      placeholder={placeholder}
    />
  );
};
