import { FunctionComponent } from "react";
import Error from "../../assets/error.png";

interface ModalProps {
  setOpen: (val: boolean) => void;
  errorMessage: string;
  isError?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
  setOpen,
  isError = true,
  errorMessage,
}) => {
  return (
    <div className="modalCont">
      <div className="modalContent">
        <button
          onClick={() => setOpen(false)}
          style={{
            border: "none",
            backgroundColor: "transparent",
            marginLeft: "auto",
          }}
        >
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
              d="M24 8L8 24"
              stroke="white"
              stroke-width="1.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 8L24 24"
              stroke="white"
              stroke-width="1.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isError && <img className="error-icon" src={Error} alt="error" />}
          <h1 className="header center">{errorMessage} </h1>
        </div>
      </div>
    </div>
  );
};
