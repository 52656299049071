import { GoogleAuthProvider, signInWithPopup, User } from "firebase/auth";
import { debounce } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import GoogleButton from "react-google-button";

import { appleProvider, auth } from "../../../firebase";
import { followUser } from "../../../screens/api/followUser";
import { getEmailExists } from "../../../screens/api/getEmailexists";
import { getUser } from "../../../screens/api/getUser";
import AppleIcon from "../../../screens/apple.svg";
import { Input } from "../../input/Input";
import { updateAudiences } from "./api/postUpdateAudience";
export interface StepProps {
  setStep: (step: number) => void;
  setPage?: (val: number) => void;
  setShowHeader?: (val: boolean) => void;
  setUserPass?: (val: { email: string; pass: string }) => void;
  setUser?: (val: User) => void;
  user?: User;
  userPass?: { email: string; pass: string };
}
export const StepFirst: FunctionComponent<StepProps> = ({
  setStep,
  setPage,
  setUserPass,
  setShowHeader,
}) => {
  useEffect(() => {
    setShowHeader?.(false);
  }, []);

  const [email, setEmail] = useState("");
  const [isEmailUsed, setIsEmailUsed] = useState<boolean | null>(null);
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [terms, setTerms] = useState(false);
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(async (res) => {
        if (res.user) {
          const token = await res.user.getIdToken();
          getUser(token).then(() => {
            var sp = new URLSearchParams(window.location.search);
            updateAudiences(
              {
                allowPush: true,
                friends: false,
                friendsPlus: false,
                innerCircle: false,
                otherUserId: sp.get("u") ?? "",
              },
              token
            ).catch((err) => {
              console.log(err);
            });
            followUser(sp.get("u") ?? "", token)
              .then(
                () =>
                  (window.location.href = "https://getonelight.com/thankyou/")
              )
              .catch(() => alert("user already followed"));
          });
        }
        setPage?.(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleAppleSignIn = () => {
    signInWithPopup(auth, appleProvider)
      .then(async (res) => {
        if (res.user) {
          const token = await res.user.getIdToken();
          getUser(token).then(() => {
            var sp = new URLSearchParams(window.location.search);
            updateAudiences(
              {
                allowPush: true,
                friends: false,
                friendsPlus: false,
                innerCircle: false,
                otherUserId: sp.get("u") ?? "",
              },
              token
            ).catch((err) => {
              console.log(err);
            });
            followUser(sp.get("u") ?? "", token)
              .then(
                () =>
                  (window.location.href = "https://getonelight.com/thankyou/")
              )
              .catch(() => {
                window.location.href = "https://getonelight.com/thankyou/";
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const debounceSearch = useCallback(
    debounce((data: string) => {
      if (data)
        getEmailExists(data)
          .then((res) => {
            if (!data) setIsEmailUsed(null);
            else setIsEmailUsed(res.data.emailExists);
          })
          .catch((e) => console.log(e));
    }, 500),
    []
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
      <Input
        onChange={(e) => {
          debounceSearch(e);
          setEmail(e);
        }}
        type="email"
        placeholder="E-mail"
      />
      {isEmailUsed && <p className="error-text">Email already in use!!!</p>}
      <Input
        onChange={setPassword}
        type="password"
        placeholder="Password (at least 6 characters long)"
      />
      <Input
        onChange={setConfirmPass}
        type="password"
        placeholder="Confirm Password"
      />
      <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
        <input
          className="check"
          type="checkbox"
          name=""
          id=""
          onChange={() => setTerms(!terms)}
        />
        <p className="base-text">
          I have read and agree to the{" "}
          <a
            style={{ textDecoration: "none" }}
            className="base-text-white"
            target="_blank"
            href="https://getonelight.com/terms"
            rel="noreferrer"
          >
            Terms and conditions.
          </a>
        </p>
      </div>
      <button
        className="button-primary base-text-white"
        disabled={
          !terms ||
          email === "" ||
          password.length < 6 ||
          password !== confirmPass ||
          isEmailUsed === true
        }
        onClick={() => {
          setUserPass?.({ email: email, pass: password });

          setStep(1);
        }}
      >
        NEXT
      </button>
      <div className="have-container">
        <p className="base-text">Already have an account?</p>

        <button
          onClick={() => {
            setPage?.(2);
          }}
          className="text-button base-text-white"
        >
          Log in
        </button>
      </div>
      <GoogleButton
        onClick={() => googleSignIn()}
        style={{ backgroundColor: "white", color: "black", width: "100%" }}
      />
      <button className="appleButton" onClick={handleAppleSignIn}>
        <img src={AppleIcon} alt="apple icon" className="appleIcon" />
        <p className="appleButtonText">Continue with Apple</p>
      </button>
    </div>
  );
};
