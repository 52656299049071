import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCGK9hWSrGHYne8R5S9cdQicYqiQDc74bU",
  authDomain: "onelight-380820.firebaseapp.com",
  projectId: "onelight-380820",
  storageBucket: "onelight-380820.appspot.com",
  messagingSenderId: "638099407164",
  appId: "1:638099407164:web:61cfdd16d3ba622cd85e39",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const storage = getStorage(app);

export const appleProvider = new OAuthProvider("apple.com");
