import { FunctionComponent, useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import { Stepper } from "../components/stepper/Stepper";
import { ScreenProps } from "./Login";
import { getUserRefered } from "./api/getUserRefered";

export const Register: FunctionComponent<ScreenProps> = ({ setPage }) => {
  const [showTitle, setShowTitle] = useState(true);
  const [name, setname] = useState("");

  useEffect(() => {
    getUserRefered(new URLSearchParams(window.location.search).get("u") ?? "")
      .then((res) => {
        setname(res.data.displayname);
      })
      .catch((err) => {});
  }, []);
  return (
    <div className="container">
      <img style={{ marginBottom: 16 }} className="logo" src={Logo} alt="" />
      {name && (
        <h1 style={{ textAlign: "start" }} className="base-text">
          You were referred by{" "}
          <span style={{ color: "white", fontWeight: 700 }}>{name}</span>
        </h1>
      )}
      <Stepper setPage={setPage} setShowHeader={setShowTitle} />
    </div>
  );
};
