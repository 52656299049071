import axios from "axios";
export interface UserCreationVariables {
  newUser: {
    name: string;
    displayname: string;
    phone: string;
    usernote: string;
    imageUri: string;
    isTastemaker: boolean;
    genderId: number;
    lastActive: Date;
    lastLat: number;
    lastLong: number;
    friends: boolean;
    friendsPlus: boolean;
    innerCircle: boolean;
    everyone: boolean;
    firebaseid: string;
  };
}
export const createUser = async (data: UserCreationVariables) => {
  return await axios.post(`https://api.onelight.app/api/user`, data.newUser);
};
